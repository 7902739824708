/* Card.css */

.ProjectsCard {
    width: 30rem;
    height: 25rem;
    max-width: 100%;
    border-radius: 8px;
    
    margin: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
 
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(40, 44, 52);
  }
  
  .Projectscard-image {
    width:23rem;
    height: 15rem;
    /* height: auto; */
    margin-top: 20px; /* Add space between top and image */
    border-radius: 8px;
    /* mix-blend-mode:multiply; */
  }
  
  .Projectscard-title {
    margin-top: 50px; 
    font-size: 1.2rem;
    color:rgb(190, 185, 185);
  }
  
  .Projectscard-attributes {
    margin-top: 20px; 
    font-size: 1.2rem;
    color:rgb(190, 185, 185);
  }

  /* For screens smaller than 768px */
   /* TO DO: every component */
@media (max-width: 768px) {
  .ProjectsCard {
    width: 100%; /* Cards will take full width of the container */
    height: auto; /* Allow the height to adjust to changes*/
    margin: 10px; /* Adjust margin to avoid overflow on screen */
  }

  .Projectscard-image {
    width: 85%; /* Scale the image with the card */
    height: auto;
  }
}