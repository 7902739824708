.Intro {
    
    height:100%;
    
    /* box-sizing:border-box; */
    
}

.Intro_br {
  display:none;
}

.Intro_content {
    margin-left: 200px;
    margin-top: 75px;
    margin-bottom: 150px;
         
}

div.Intro_content h1 {
    color:rgb(190, 185, 185);
    font-size: 13vh;
    background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 0.8s infinite linear;
    margin:5px;
 
}

@keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 600px;
    }
    100% {
      background-position: 600px;
    }
  }

div.Intro_content p{
    color:rgb(190, 185, 185);
    font-size: 1.2rem;
    margin-left:15px;
    
}

div.Intro_content button {
    border:2px solid rgb(190, 185, 185);
    background-color: transparent;
    color:rgb(190, 185, 185);
    padding: 15px 32px;
    font-size: 1rem;
    margin-top: 5px;
    margin-left:15px;
}

/* Phones */
@media (max-width: 767px) { 
  

  .Intro_content {
    display:grid;
    align-items: center;
    margin-left: 10px;
    text-align: center;
  }

  div.Intro_content h1 {
    font-size: 7vh;
    text-align: center;
 
}

  div.Intro_content p{
    margin-top:30px;
    
  }

  .Intro_br {
    display:static;
  }

  div.Intro_content button { 
    margin: 30px 100px 100px 100px;/* top right bottom left */
  }


}

/* Tablets */
@media (min-width: 768px) and (max-width: 1440px) {
 

  div.Intro_content h1 {
    font-size: 8vh;
    text-align: center;
    
}

  .Intro_content {
    display:grid;
    align-items: center;
    text-align: center;
    margin-left:10px;
    
  
  }

  .Intro_br {
    display:static;
  }

  div.Intro_content button { 
    margin: 30px 150px 150px 150px;/* top right bottom left */
  }
  
}