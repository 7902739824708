.Skills {
    height:100%;
}

.Skills_title {
    text-align: center;
    padding-top: 56px;
    padding-bottom: 0px;
    
}

div.Skills_title h1 {
    font-size: 2.8rem;
    color:rgb(190, 185, 185);
}

.Skills_list {
    padding-top: 0px;
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    flex-grow:1;
    flex-shrink: 1
}

@media (max-width: 768px) {
    .Skills_list {
        display:grid;
        grid-template-columns: repeat(auto-fill, minmax(min(200px,100%), 1fr, 1fr));
        
    }

    .Skills {
        width: 100%;
    }
}