/* Footer Styles */
.Footer {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  justify-content: space-between;
  align-items: center;
  background-color: rgb(190, 185, 185);
  padding: 1rem 2%; 
  width: 100%;  
  position: relative;
  min-height: 10vh;  
  box-sizing: border-box;  /* Include padding in width/height */
}

/* Copyright Section */
.Footer_copyright p {
  color: rgb(40, 44, 52);
  margin: 0;
  font-size: 1rem; 
}

/* Navigation Links */
.Footer_nav_links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Wrap links when needed */
  gap: 1rem;  
  

}

.Footer_nav_links a {
  color: rgb(40, 44, 52);
  text-decoration: none;
  font-size: 1rem; 
}

.Footer_social_links {
  display: flex;
  justify-content: center;
  gap: 1rem; /* Spacing between the icons */
}

/* Individual Social Media Icons */
.fa {
  display: flex;  
  justify-content: center;  /* Horizontal centering */
  align-items: center;  /* Vertical centering */
  font-size: 1.5rem;  
  width: 3rem;
  height: 3rem;
  margin: 0.5rem;
  border-radius: 50%;
  background: rgb(40, 44, 52);  /* Background color for the icons */
  color: rgb(190, 185, 185);  /* Actual Icon color */
  text-align: center;
  text-decoration: none;
}



.fa:hover {
  opacity: 0.7;
}

/* Ensure Footer Stays at Bottom */
body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
}

/* Footer positioned at the bottom */
.Footer {
  margin-top: auto;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .Footer {
      flex-direction: column;
      text-align: center;
      gap: 1rem; /* Scalable spacing between sections */
  }

  .fa {
      font-size: 1.25rem; /* Adjust icon size for mobile */
      width: 2rem;
      height: 2rem;
  }
}
