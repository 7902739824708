/* Card.css */
.SkillsCard {
    width: 8rem;
    height: 12rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color:rgb(190, 185, 185);
    align-self: center;
  }
  
  .Skillscard-image {
    width: 80%;
    height: 95px;
    /* height: auto; */
    margin-top: 20px; 
    border-radius: 8px;
    mix-blend-mode:darken;
  }
  
  .Skillscard-title {
    margin-top: 20px; 
    font-size: 1.2rem;
    color:rgb(40, 44, 52);
  }
  