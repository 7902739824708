.Projects {
    height:100vh;
    background-color: rgb(190, 185, 185);
}

.Projects_title {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 0px;
    
}

div.Projects_title h1 {
    font-size: 2.8rem;
    color:rgb(40, 44, 52);
   
}


.Projects_list {
    padding-top: 0px;
    display: flex;
    justify-content:center;
    /* May need to specify amt of cards on page */
    flex-wrap: wrap; /* Wrap cards to the next line if needed */ 
    gap: 20px; /* Add space between cards */
    margin: 0 auto;
    padding: 1rem;

}