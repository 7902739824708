html, body {
  margin: 0;
  /* height: 100%; */
  display: flex;
  flex-direction: column;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(37, 23, 46); */
  background-color: rgb(40, 44, 52);
  /* border: 5px solid rgb(190, 185, 185);  */
  border-top: 5px solid rgb(190, 185, 185); 
  border-right: 5px solid rgb(190, 185, 185); 
  border-left: 5px solid rgb(190, 185, 185);
}

.page-content {
  height: var(--custom-height);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  html, body {
    margin: 0;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
  }

  
}