html { scroll-behavior: smooth; } /* smooth scrolling behavior for all anchor tags */

.Header {
    display:flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(40, 44, 52);
   
    /* height: 70px; */
    /* margin-top: 0px; */
    /* margin-left: 10px;
    margin-right: 10px; */
    /* border at bottom, like a flap when it scrolls */
    position: sticky;
    top:0;
    z-index: 1000;
    
    /* box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.2); Optional: Adds subtle shadow */

    
}

.Header_title {
    /*add logo and center logo to left of title*/
    display:flex;
    align-items:flex-end;
    color: rgb(190, 185, 185);
    margin-left: 10px;
    background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
}
div.Header_title h1 {
  background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
}
.Header_hrefs {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    color:rgb(190, 185, 185);
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;
    font-size: 1.35rem;
    padding: 0.5rem 1rem; /* Responsive padding for links */
  }
  

 div.Header_hrefs a:visited {
    color:rgb(190, 185, 185);
  }

  div.Header_hrefs  a:link {
    color:rgb(190, 185, 185);
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
  }

  div.Header_hrefs  a:hover {
    color:rgb(190, 185, 185);
  }