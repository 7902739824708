.Contact {
  height:100vh;
}

.Contact_title {
text-align: center;
  padding-top: 56px;
  padding-bottom: 0px;
}

div.Contact_title h1 {
  font-size: 2.8rem;
  color:rgb(190, 185, 185);
}

.Contact_email {
text-align: center;
color:rgb(190, 185, 185);
font-size: 1rem;
}

.Contact_form_container {
width: 38rem; 
height: 20rem;
background-color: rgb(190, 185, 185); 
color: rgb(40, 44, 52);  
display: grid;
grid-template-columns: 1fr 1fr; /* two equal columns */
gap: 1.5rem;
margin: 0 auto ; /* Center horizontally */
padding: 1rem;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
border-radius: 4px; /* Rounded borders */
margin-bottom: 20px;
}

.Contact_form_container label, 
.Contact_form_container input, 
.Contact_form_container textarea {
 /* Ensure label and inputs take full width */
  
}

.Contact_form_container input, 
.Contact_form_container textarea {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  width: 16rem; /* Inputs take full width */
  
  font-size: 1rem;
  border: 1px solid rgb(46, 50, 58);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

/* Specific styling for textarea */
.Contact_form_container textarea {
height: 8rem; /* Adjusted height for textarea */
}

input[type=submit] {
  background-color: rgb(46, 50, 58);
  color: white;
  height:3rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  grid-column: span 2; /* Make the submit button span across both columns */
  justify-self: center; 
}

/* MEDIA QUERIES */

/* For tablets and smaller devices (max width 768px) */
@media (max-width: 767px) {
.Contact_form_container {
    grid-template-columns: 1fr; 
    /* Single column layout */
    width: 90%; 

    /* display:flex;
    flex-direction: column;
    flex-shrink:1; */

   
}

div.Contact_title h1 {
    font-size: 2.2rem; 
}

.Contact_email {
    font-size: 0.9rem; /* Slightly smaller text */
}

.Contact_form_container input, 
.Contact_form_container textarea {
    /* width: 80%; 
  
    padding-left:2rem; */
    margin: auto;
    display:flex;
    flex-direction: column;
    flex-shrink:1;

}


}

/* For phones (max width 480px) */
@media (max-width: 480px) {
div.Contact_title h1 {
    font-size: 1.8rem;
}

.Contact_email {
    font-size: 0.8rem;
}

input[type="submit"] {
    width: 100%; /* Submit button spans the full width */
    grid-column: 1; /* Single column */
}

.Contact_form_container {
  width: 20rem; 
  height: 35rem;
  background-color: rgb(190, 185, 185); 
  color: rgb(40, 44, 52); 
  display: grid;
  grid-template-columns: 1fr; /* one column */
  gap: 1.5rem;
  margin: 0 auto ; /* Center horizontally */
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  border-radius: 4px; /* Rounded borders */
  margin-bottom: 20px;
}
}

